* {
  /* Defaults */
  margin: 0;
  margin-bottom: 0;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  

  /* Boxes & Cards */
  --box-border-radius: 10px;
  --box-bg: white;
  --box-text: black;

  /* Background */
  --bg-fill: lightgrey;  

  /* Headings & Menu*/
  --ticket-icon: cyan;
  --headings-icon: cyan;
  --headings-fill: #051e34;
  --headings-text: white;
  --menu-divider: #444;
  --menu-open: rgba(71, 98, 130, 0.2);
  --menu-link-active: #FEA200;
  --menu-link-inactive: #D3D3D3;
  --menu-subtext-hidden: rgba(0,0,0,0);
  --menu-subtext-visible: rgba(255,255,255,0.5);

  /* Dialog Popups*/
  --dialog-headings-fill: #D3D3D3;
  --dialog-headings-text: #051e34;

  /* Icon colors */
  --icon-action: darkorange;
  --icon-text: white;
  --icon-avatar: darkorange;

  /* Table */ 
  --table-hover: lightgrey;
  --table-customer-border: rgb(161, 159, 159);

  /* Ticket count bubble */
  --ticket-count: #19785d;

  /* Single Ticket Tabs*/  
  --ticket-tab-fill: #32557f;
  --ticket-tab-icon: #69eaff;

  /* Reply Box */
  --reply-border: lightgrey;
  --reply-text: #111;
  --reply-bg-fill: #F5FdFe;

  /* WYSIWYG toolbar for Reply Box */
  --wys-border: lightgrey;
  --wys-bg-fill: #051e34;
  --wys-tool-fill: white;

  --success: #19785d;
  
}

.MuiContainer-root {
  padding-left: 0px;
  padding-right: 0px;
 }
 /* Left side of forms */
 .MuiFormControl-root {
   margin-left: 0px !important;
 }
/* Colors for title header of Popup Dialog Boxes */
 .MuiDialogTitle-root {
  background-color: var(--dialog-headings-fill);
  color: var(--dialog-headings-text);
 }

 /* Padding inside Popup Dialog Boxes */
 .MuiDialogContent-root {
  margin-left: 20px !important;
  margin-right: 20px !important;
 }
 /* Rounded Corner for Popup Dialog boxes */
 .MuiDialog-paper {
  border-radius: 10px !important;
}
.MuiCardHeader-root {
  padding: 10px !important;
}
.MuiBox-root {
  padding: 0px 10px !important; 
}

.react-grid-layout {
  position: relative !important;
}
.react-grid-item.react-grid-placeholder {
  background-color:var(--headings-icon) !important;
}

.dragarrow {
  pointer-events: none;
  position: absolute;
  bottom: 5px;
  right: 5px;
  line-height: 0;
  color:var(--ticket-tab-icon);
  transform: rotate(45deg);
  width: 25px;
  height: 25px;
}

.dragarrowDark {
  pointer-events: none;
  position: absolute;
  bottom: 5px;
  right: 5px;
  line-height: 0;
  color:var(--headings-fill);
  transform: rotate(45deg);
  width: 20px;
  height: 20px;
}


/* NO EDITING BEYOND THIS POINT UNLESS YOU KNOW WHAT U R DOING*/

/* For Reply section in TicketSingleReply ****************************************************************************************************** */
.wrapper-class {
  padding: rem;
  border: 1px solid var(--reply-border);
  color: var(--reply-text);
}
.editor-class {
  background-color: var(--reply-bg-fill);
  padding: 1rem;
  height: 350px;
}
.rdw-editor-toolbar {
  padding: 6px 5px 0;
  border-radius: 2px;
  border: 1px solid var(--wys-border);
  background: var(--wys-bg-fill);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
  -webkit-user-select: none;
  user-select: none;
}
.rdw-option-wrapper {
  background-color: var(--wys-tool-fill);
  min-width: 20px;
  height: 20px;
  margin: 0 3px;
}
.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.icon-text-group {
  display: flex;
  align-items: center;
}
.dang-space {
  width: 5px;
}